import React, { useState, useEffect } from 'react';
import Quiz from './Quiz';
import './Inicial.css';

const CapturaLeads = ({ onSubmit, setUserInfos }) => {
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [empresa, setEmpresa] = useState('');
  const [cargo, setCargo] = useState('');
  const [whatsapp, setWhatsapp] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (nome && email && empresa && cargo && whatsapp) {
      onSubmit({ nome, email, empresa, cargo, whatsapp });
      const array = [];
      array.push(nome);
      array.push(email);
      array.push(empresa);
      array.push(cargo);
      array.push(whatsapp);
      setUserInfos(array);
    } else {
      alert('Por favor, preencha todos os campos.');
    }
  };

  return (
    <div className="captura-leads">
      <h1>Preencha seus dados para iniciar o Quiz</h1>
      <form onSubmit={handleSubmit} className='forms-lead'>
        <input type="text" placeholder="Nome" value={nome} onChange={(e) => setNome(e.target.value)} required />
        <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
        <input type="text" placeholder="Empresa" value={empresa} onChange={(e) => setEmpresa(e.target.value)} required />
        <input type="text" placeholder="Cargo na Empresa" value={cargo} onChange={(e) => setCargo(e.target.value)} required />
        <input type="tel" placeholder="WhatsApp" value={whatsapp} onChange={(e) => setWhatsapp(e.target.value)} required />
        <button type="submit">IR PARA O QUIZ</button>
      </form>
    </div>
  );
};

const Inicial = () => {
  const [iniciado, setIniciado] = useState(false);  
  const [leadCapturado, setLeadCapturado] = useState(false);
  const [userInfos, setUserInfos] = useState([]);

  useEffect(() => {
    const metaTag = document.createElement('meta');
    metaTag.name = 'viewport';
    metaTag.content = 'width=device-width, initial-scale=1.0, user-scalable=no';
    document.head.appendChild(metaTag);
    return () => {
      document.head.removeChild(metaTag);
    };
  }, []);

  const iniciarQuiz = () => {
    setIniciado(true);
  };

  const handleLeadSubmit = (data) => {
    setLeadCapturado(true);
  };


  const StartView = () => {

    if (!iniciado) {

      return (

        <div className='title-page'>
          <h1>Diagnóstico de empresa</h1>
          <div className='div-texto'>
            <h2 className='texto-inicial'>
              Este teste ajuda a avaliar o quão maduros estão os processos de gestão de pessoas na sua empresa. Ele destaca os principais aspectos a considerar para gerar resultados. Use-o como guia para identificar quais processos implementar e monitorar, relacionados à cultura, desempenho e gestão de pessoas. São 9 áreas diferentes, cada uma avaliada separadamente.
            </h2>
          </div>
          <div className='div-texto'>
            <h2 className='texto-inicial-2'>
              Para cada item, marque SIM se estiver totalmente presente na sua empresa, ou NÃO se estiver ausente ou apenas parcialmente implementado.
            </h2>
          </div>
        
          <button className="pushable" onClick={iniciarQuiz}>
            <span className="shadow"></span>
            <span className="edge"></span>
            <span className="front">Iniciar Quiz</span>
          </button>
        </div>

      );

    } else {

      return false;

    }

  };


  return (
    <div className="inicial">
      {!leadCapturado ? (
        <CapturaLeads onSubmit={handleLeadSubmit} setUserInfos={setUserInfos} />
      ) : <StartView />}
      {leadCapturado && iniciado && <Quiz userInfos={userInfos} />}
    </div>
  );
};

export default Inicial;