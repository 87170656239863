import React, { useState, useEffect } from 'react';

import Pergunta from './Pergunta';
import PaginaVerde from './PaginaVerde';
import PaginaAmarela from './PaginaAmarela';
import PaginaVermelha from './PaginaVermelha';

import './Quiz.css'

const Quiz = ({ userInfos }) => {
  const [temaAtual, setTemaAtual] = useState(0);
  const [perguntaAtual, setPerguntaAtual] = useState(0);
  const [quantidadeSim, setQuantidadeSim] = useState(0);
  const [perguntasRespondidas, setPerguntasRespondidas] = useState(0); 
  const [respostas, setRespostas] = useState([]);

  const [transitionState, setTransitionState] = useState(true);


  const temas = [
    {
      tema: "1. Identidade e cultura",
      perguntas: [
        "A empresa tem a história, missão, visão e valores mapeados e documentados?",
        "Há divulgação periódica sua estratégia e identidade?",
        "Esta informação pode ser facilmente acessadas por qualquer pessoa?",
        "Possui elementos que identifique a empresa internamente, como símbolos, frases e outros?",
        "A adesão das pessoas à cultura e à estratégia é avaliada periodicamente?",
        
      ]
    },
    {
      tema: "2. Endomarketing",
      perguntas: [
        "A empresa divulga internamente seus eventos e conquistas?",
        "Há divulgação periódica sua estratégia e identidade?",
        "Há um calendário de ações a serem realizadas?",
        "As ações são escolhidas e realizadas com o envolvimento das pessoas?",
        "A empresa avalia as ações que realiza com a equipe interna?",
      ]
    },
    {
      tema: "3. Arquitetura e Estrutura organizacional",
      perguntas : [
        "A empresa possui organograma estruturado, documentado e atualizado da empresa?",
        "O organograma é divulgado para a empresa?",
        "Há descrição de cargos atualizada e acessível a todos?",
        "Existem indicadores mapeados para todas as áreas?",
        "As pessoas possuem clareza do que devem produzir com seus trabalhos?",
      ]
    }, 
    {
      tema: "4. Gestão de desempenho", 
      perguntas: [
        "Há objetivos e metas estabelecidos para toda a empresa e divulgados para todos?",
        "Existem rotinas de gestão para discutir resultados da empresa e das equipes?",
        "Possui rotinas de gestão para os líderes ouvirem seus liderados e para darem feedbacks?",
        "A empresa realiza avaliação de desempenho periodicamente?",
        "A empresa reconhece o desempenho por meios financeiros ou sociais?",
      ]
    },
    {
      tema: "5. Carreiras e remunerações",
      perguntas: [
        "A empresa tem uma política clara de remuneração?",
        "Possui estrutura de crescimento e carreira clara e documentada?",
        "Esta política de remuneração é divulgada para todos da empresa?",
        "As pessoas estão localizadas adequadamente na política de remuneração?",
        "Há avaliação periódica sobre a adequação da política de remuneração com o mercado?",
      ]
    }, 
    {
      tema: "6. Onbording – Integração",
      perguntas: [
        "Existe um processo de integração (onboarding) padronizado e documentado?",
        "Há treinamentos iniciais padronizados para quem chega na empresa?",
        "As pessoas são acompanhadas durante o período de experiência?",
        "Existe avaliação pelo gestor e pela pessoa que chegou durante o período de experiência?",
        "São documentadas ações de correção ou adequação durante este período?",
      ]
    },
    {
      tema: "7. Treinamento e desenvolvimento",
      perguntas: [
        "A empresa possui uma política de treinamento e desenvolvimento?",
        "Há estratégias de desenvolvimento mapeadas e documentadas?",
        "A empresa executa ações de treinamentos ou desenvolvimento?",
        "Há avaliação das ações de treinamento e desenvolvimento?",
        "Existe plano de desenvolvimento individual documentado, registrado e acompanhado?",
      ]
    },
    {
      tema: "8. Captação e seleção", 
      perguntas: [
        "A empresa tem documentado o perfil ideal para cada função?",
        "Há uma política de divulgação de vaga e captação de pessoas?",
        "Existe uma ferramenta para centralizar as informações sobre seleção?",
        "As avaliações dos candidatos durante o processo são padronizadas?",
        "A empresa controla o retorno para os candidatos?",
      ]
    }, 
    {
      tema: "9. Gestão e indicadores sobre pessoas (People analytics)",
      perguntas: [
        "Há indicadores para avaliar a área de pessoas?",
        "A empresa consegue ter clareza do desempenho da sua equipe por meio dos indicadores?",
        "É possível avaliar quanto a empresa é atrativa no mercado por meio de indicadores?",
        "Há métricas sobre a saúde, segurança e qualidade de vida das pessoas?",
        "A empresa consegue medir sua capacidade de reter profissionais?",
      ]
    }
  ];

  useEffect(() => {

    // console.log(respostas);

    if (
      perguntasRespondidas === 0 || 
      perguntasRespondidas === 5 || 
      perguntasRespondidas === 10 || 
      perguntasRespondidas === 15 || 
      perguntasRespondidas === 20 || 
      perguntasRespondidas === 25 || 
      perguntasRespondidas === 30 || 
      perguntasRespondidas === 35 ||
      perguntasRespondidas === 40) {

      setTransitionState(true);
    } else {
      setTransitionState(false);
    }

  }, [perguntasRespondidas]);


  const calcularProgresso = () => {
    const totalPerguntas = temas.reduce((total, tema) => total + tema.perguntas.length, 0);
    const perguntasRespondidas = temaAtual * temas[0].perguntas.length + perguntaAtual;
    return Math.round((perguntasRespondidas / totalPerguntas) * 102);
  };

  const progresso = calcularProgresso();


  const proximaPergunta = () => {
    if (perguntaAtual < temas[temaAtual].perguntas.length - 1) {
      setPerguntaAtual(perguntaAtual + 1);
    } else if (temaAtual < temas.length - 1) {
      setTemaAtual(temaAtual + 1);
      setPerguntaAtual(0);
    } 

    setPerguntasRespondidas(perguntasRespondidas + 1);
  };

  const perguntaAnterior = () => {
    if (perguntaAtual > 0) {
      setPerguntaAtual(perguntaAtual - 1);
    } else if (temaAtual > 0) {
      setTemaAtual(temaAtual - 1);
      setPerguntaAtual(temas[temaAtual - 1].perguntas.length - 1);
    } 

    const indiceResposta = temaAtual * temas[0].perguntas.length + perguntaAtual - 1;
    if (respostas[indiceResposta] === "SIM") {
      setQuantidadeSim(quantidadeSim - 1);
    }

    if (perguntasRespondidas > 0) {
      setPerguntasRespondidas(perguntasRespondidas - 1);
    }
  };

  const handleResposta = (resposta) => {
    if (perguntasRespondidas < 45) {
      if (resposta === "SIM") {
        setQuantidadeSim(quantidadeSim + 1);
      }

      const indiceResposta = temaAtual * temas[0].perguntas.length + perguntaAtual;
      const novasRespostas = [...respostas];
      novasRespostas[indiceResposta] = resposta;
      setRespostas(novasRespostas);

      proximaPergunta(); 
    }
  };

  const reiniciarQuiz = () => {
    setTemaAtual(0);
    setPerguntaAtual(0);
    setQuantidadeSim(0);
    setPerguntasRespondidas(0);
    setRespostas([]);
  };

  if (perguntasRespondidas === 45) {
    if (quantidadeSim >= 41) {
      return <PaginaVerde reiniciarQuiz={reiniciarQuiz} respostas={respostas} quantidadeSim={quantidadeSim} userInfos={userInfos} />;
    } else if (quantidadeSim >= 31 && quantidadeSim <= 40) {
      return <PaginaAmarela reiniciarQuiz={reiniciarQuiz} respostas={respostas} quantidadeSim={quantidadeSim} userInfos={userInfos} />;
    } else {
      return <PaginaVermelha reiniciarQuiz={reiniciarQuiz} respostas={respostas} quantidadeSim={quantidadeSim} userInfos={userInfos} />;
    }
  }

  const TransitionView = () => {
    return(
    <div className="title-quiz">

      <h1>Vamos falar sobre: {temas[temaAtual].tema}!</h1>

      <button className='button-title' onClick={() => {setTransitionState(false)}}>
        <span style={{color: '#1e1e1e'}}>
          Vamos lá!
        </span>
      </button>
      
    </div>
    );
  };

  if (!transitionState) {

    return (
      <div className="quiz">
        <div className='container-bar'>
          <div className="progresso-barra" style={{ width: `${progresso}%` }} />
        </div>
        <h1>{temas[temaAtual].tema}</h1>
        <Pergunta texto={temas[temaAtual].perguntas[perguntaAtual]} handleResposta={handleResposta} perguntaAnterior={perguntaAnterior}/>
        {/* <p>Quantidade de respostas "SIM": {quantidadeSim}</p> */}
      </div>
    );

  } else {

    return <TransitionView />;

  }
    
};

export default Quiz;