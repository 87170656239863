import React from 'react';

import './Pergunta.css'

const Pergunta = ({ texto, handleResposta, perguntaAnterior }) => {
  return (
    <div className="pergunta">
      <div className='container-texto-linha'>
        <div className='container-texto'>
          <h2 style={{color: '#1e1e1e',
                      fontSize: '18px', 
                      textAlign: 'center',
                      minHeight: 'auto',
                      maxHeight: '150px',
                      fontWeight: '600'
                      }}>{texto}</h2>
        </div>
      </div>

      <div className="botoes-respostas" >
        <button className='button-yes' onClick={() => handleResposta("SIM")}>SIM</button>
        <button className='button-no' onClick={() => handleResposta("NÃO")}>NÃO</button>
        <button className='button-back' onClick={perguntaAnterior}>VOLTAR</button>
      </div>            

    </div>
  );
};

export default Pergunta;