import React, { useRef } from 'react';
import { FaRegStar, FaStar, FaCircle } from "react-icons/fa";
import html2canvas from 'html2canvas'; 

import respostaPDF from '../assets/NEOS - Desenvolvimento.pdf'


import './PaginaVermelha.css'


const PaginaVermelha = ({ reiniciarQuiz, respostas, quantidadeSim, userInfos }) => {

  const paginaVermelhaRef = useRef(null);

  const enviarEmail = () => {

    const url = 'https://quiz.neosdesenvolvimento.com.br/mailService.php';

    const newSub = 'NOVO DIAGNÓSTICO - ' + userInfos[2] + ' (' + userInfos[0] + ')';
    const newMessage = 'INFORMAÇÕES: \n \n NOME: ' + userInfos[0] +
    '\n EMAIL: ' + userInfos[1] +
    '\n EMPRESA: ' + userInfos[2] + 
    '\n CARGO: ' + userInfos[3] + 
    '\n WHATSAPP: ' + userInfos[4] + 
    '\n\n RESULTADO: \n PONTUAÇÃO TOTAL:' + quantidadeSim + '\n\n Identidade e cultura \n QUESTÃO 1 - ' + respostas[0]  + 
    '\n QUESTÃO 2 - ' + respostas[1] + 
    '\n QUESTÃO 3 - ' + respostas[2] + 
    '\n QUESTÃO 4 - ' + respostas[3] +
    '\n QUESTÃO 5 - ' + respostas[4] +
    '\n\n Endomarketing\n QUESTÃO 6 - ' + respostas[5] +
    '\n QUESTÃO 7 - ' + respostas[6] +
    '\n QUESTÃO 8 - ' + respostas[7] +
    '\n QUESTÃO 9 - ' + respostas[8] +
    '\n QUESTÃO 10 - ' + respostas[9] +
    '\n \nArquitetura e Estrutura organizacional\n QUESTÃO 11 - ' + respostas[10] +
    '\n QUESTÃO 12 - ' + respostas[11] +
    '\n QUESTÃO 13 - ' + respostas[12] +
    '\n QUESTÃO 14 - ' + respostas[13] +
    '\n QUESTÃO 15 - ' + respostas[14] +
    '\n \nGestão de desempenho\n QUESTÃO 16 - ' + respostas[15] +
    '\n QUESTÃO 17 - ' + respostas[16] +
    '\n QUESTÃO 18 - ' + respostas[17] +
    '\n QUESTÃO 19 - ' + respostas[18] +
    '\n QUESTÃO 20 - ' + respostas[19] +
    '\n \nCarreiras e remunerações\n QUESTÃO 21 - ' + respostas[20] +
    '\n QUESTÃO 22 - ' + respostas[21] +
    '\n QUESTÃO 23 - ' + respostas[22] +
    '\n QUESTÃO 24 - ' + respostas[23] +
    '\n QUESTÃO 25 - ' + respostas[24] +
    '\n \nOnbording – Integração\n QUESTÃO 26 - ' + respostas[25] +
    '\n QUESTÃO 27 - ' + respostas[26] +
    '\n QUESTÃO 28 - ' + respostas[27] +
    '\n QUESTÃO 29 - ' + respostas[28] +
    '\n QUESTÃO 30 - ' + respostas[29] +
    '\n \nTreinamento e desenvolvimento\nQUESTÃO 31 - ' + respostas[30] +
    '\n QUESTÃO 32 - ' + respostas[31] +
    '\n QUESTÃO 33 - ' + respostas[32] +
    '\n QUESTÃO 34 - ' + respostas[33] +
    '\n QUESTÃO 35 - ' + respostas[34] +
    '\n \nCaptação e seleção\n QUESTÃO 36 - ' + respostas[35] +
    '\n QUESTÃO 37 - ' + respostas[36] +
    '\n QUESTÃO 38 - ' + respostas[37] +
    '\n QUESTÃO 39 - ' + respostas[38] +
    '\n QUESTÃO 40 - ' + respostas[39] +
    '\n \nGestão e indicadores sobre pessoas (People analytics)\n QUESTÃO 41 - ' + respostas[40] +
    '\n QUESTÃO 42 - ' + respostas[41] +
    '\n QUESTÃO 43 - ' + respostas[42] +
    '\n QUESTÃO 44 - ' + respostas[43] +
    '\n QUESTÃO 45 - ' + respostas[44];
 


       

    const data = {
      to: 'contato@neosdesenvolvimento.com.br',
      subject: newSub,
      message: newMessage
    };

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    .then(response => {
      console.log('E-mail enviado com sucesso!');
    })
    .catch(error => {
      console.log(error);
    });


    const dataClient = {
      to: userInfos[1],
      subject: newSub,
      message: newMessage
    };

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dataClient),
    })
    .then(response => {
      console.log('E-mail enviado com sucesso!');
    })
    .catch(error => {
      console.log(error);
    });
    
  };

  enviarEmail();

  const capturarTelaVermelha = () => {
    if (paginaVermelhaRef.current) {
      html2canvas(paginaVermelhaRef.current).then(canvas => {
        canvas.toBlob(blob => {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = 'captura_tela.png';
          link.click();
        });
      });
    }
  };

  const baixarPDF = () => {
    window.open(respostaPDF, '_blank');
  };

  return (
    <div className='pagina-vermelha' ref={paginaVermelhaRef}>
      <h1>É preciso melhorar com urgência!</h1>
      
      <div className='icones'> 
        <FaStar className='star-incomplete'/>
        <FaRegStar className='star-complete'/>
        <FaRegStar className='star-complete'/>
      </div>


      <p>Sua empresa possui um resultado negativo ({quantidadeSim}). É preciso dar foco e atenção para os problemas de Gestão de Pessoas que podem estar minando seus resultados, o engajamento da equipe e te sobrecarregando como liderança.</p>

      <div className='scores-container'>

        <div className='score-line'>
          <div className='score-name'>
            <span>Identidade e cultura:</span>
          </div>
          <div className='score-circles'>
            <FaCircle className='question-icon' style={respostas[4] == 'SIM' ? {color: '#3b9756'} : {color: 'rgb(200, 57, 57)'}}/>
            <FaCircle className='question-icon' style={respostas[3] == 'SIM' ? {color: '#3b9756'} : {color: 'rgb(200, 57, 57)'}}/>
            <FaCircle className='question-icon' style={respostas[2] == 'SIM' ? {color: '#3b9756'} : {color: 'rgb(200, 57, 57)'}}/>
            <FaCircle className='question-icon' style={respostas[1] == 'SIM' ? {color: '#3b9756'} : {color: 'rgb(200, 57, 57)'}}/>
            <FaCircle className='question-icon' style={respostas[0] == 'SIM' ? {color: '#3b9756'} : {color: 'rgb(200, 57, 57)'}}/>
          </div>
        </div>

        <div className='score-line'>
          <div className='score-name'>
            <span>Endomarketing:</span>
          </div>
          <div className='score-circles'>
            <FaCircle className='question-icon' style={respostas[9] == 'SIM' ? {color: '#3b9756'} : {color: 'rgb(200, 57, 57)'}}/>
            <FaCircle className='question-icon' style={respostas[8] == 'SIM' ? {color: '#3b9756'} : {color: 'rgb(200, 57, 57)'}}/>
            <FaCircle className='question-icon' style={respostas[7] == 'SIM' ? {color: '#3b9756'} : {color: 'rgb(200, 57, 57)'}}/>
            <FaCircle className='question-icon' style={respostas[6] == 'SIM' ? {color: '#3b9756'} : {color: 'rgb(200, 57, 57)'}}/>
            <FaCircle className='question-icon' style={respostas[5] == 'SIM' ? {color: '#3b9756'} : {color: 'rgb(200, 57, 57)'}}/>
          </div>
        </div>

        <div className='score-line'>
          <div className='score-name'>
            <span>Arquitetura e Estrutura organizacional:</span>
          </div>
          <div className='score-circles'>
            <FaCircle className='question-icon' style={respostas[14] == 'SIM' ? {color: '#3b9756'} : {color: 'rgb(200, 57, 57)'}}/>
            <FaCircle className='question-icon' style={respostas[13] == 'SIM' ? {color: '#3b9756'} : {color: 'rgb(200, 57, 57)'}}/>
            <FaCircle className='question-icon' style={respostas[12] == 'SIM' ? {color: '#3b9756'} : {color: 'rgb(200, 57, 57)'}}/>
            <FaCircle className='question-icon' style={respostas[11] == 'SIM' ? {color: '#3b9756'} : {color: 'rgb(200, 57, 57)'}}/>
            <FaCircle className='question-icon' style={respostas[10] == 'SIM' ? {color: '#3b9756'} : {color: 'rgb(200, 57, 57)'}}/>
          </div>
        </div>

        <div className='score-line'>
          <div className='score-name'>
            <span>Gestão de desempenho:</span>
          </div>
          <div className='score-circles'>
            <FaCircle className='question-icon' style={respostas[19] == 'SIM' ? {color: '#3b9756'} : {color: 'rgb(200, 57, 57)'}}/>
            <FaCircle className='question-icon' style={respostas[18] == 'SIM' ? {color: '#3b9756'} : {color: 'rgb(200, 57, 57)'}}/>
            <FaCircle className='question-icon' style={respostas[17] == 'SIM' ? {color: '#3b9756'} : {color: 'rgb(200, 57, 57)'}}/>
            <FaCircle className='question-icon' style={respostas[16] == 'SIM' ? {color: '#3b9756'} : {color: 'rgb(200, 57, 57)'}}/>
            <FaCircle className='question-icon' style={respostas[15] == 'SIM' ? {color: '#3b9756'} : {color: 'rgb(200, 57, 57)'}}/>
          </div>
        </div>

        <div className='score-line'>
          <div className='score-name'>
            <span>Carreiras e remunerações:</span>
          </div>
          <div className='score-circles'>
            <FaCircle className='question-icon' style={respostas[24] == 'SIM' ? {color: '#3b9756'} : {color: 'rgb(200, 57, 57)'}}/>
            <FaCircle className='question-icon' style={respostas[23] == 'SIM' ? {color: '#3b9756'} : {color: 'rgb(200, 57, 57)'}}/>
            <FaCircle className='question-icon' style={respostas[22] == 'SIM' ? {color: '#3b9756'} : {color: 'rgb(200, 57, 57)'}}/>
            <FaCircle className='question-icon' style={respostas[21] == 'SIM' ? {color: '#3b9756'} : {color: 'rgb(200, 57, 57)'}}/>
            <FaCircle className='question-icon' style={respostas[20] == 'SIM' ? {color: '#3b9756'} : {color: 'rgb(200, 57, 57)'}}/>
          </div>
        </div>

        <div className='score-line'>
          <div className='score-name'>
            <span>Onbording – Integração:</span>
          </div>
          <div className='score-circles'>
            <FaCircle className='question-icon' style={respostas[29] == 'SIM' ? {color: '#3b9756'} : {color: 'rgb(200, 57, 57)'}}/>
            <FaCircle className='question-icon' style={respostas[28] == 'SIM' ? {color: '#3b9756'} : {color: 'rgb(200, 57, 57)'}}/>
            <FaCircle className='question-icon' style={respostas[27] == 'SIM' ? {color: '#3b9756'} : {color: 'rgb(200, 57, 57)'}}/>
            <FaCircle className='question-icon' style={respostas[26] == 'SIM' ? {color: '#3b9756'} : {color: 'rgb(200, 57, 57)'}}/>
            <FaCircle className='question-icon' style={respostas[25] == 'SIM' ? {color: '#3b9756'} : {color: 'rgb(200, 57, 57)'}}/>
          </div>
        </div>

        <div className='score-line'>
          <div className='score-name'>
            <span>Treinamento e desenvolvimento:</span>
          </div>
          <div className='score-circles'>
            <FaCircle className='question-icon' style={respostas[34] == 'SIM' ? {color: '#3b9756'} : {color: 'rgb(200, 57, 57)'}}/>
            <FaCircle className='question-icon' style={respostas[33] == 'SIM' ? {color: '#3b9756'} : {color: 'rgb(200, 57, 57)'}}/>
            <FaCircle className='question-icon' style={respostas[32] == 'SIM' ? {color: '#3b9756'} : {color: 'rgb(200, 57, 57)'}}/>
            <FaCircle className='question-icon' style={respostas[31] == 'SIM' ? {color: '#3b9756'} : {color: 'rgb(200, 57, 57)'}}/>
            <FaCircle className='question-icon' style={respostas[30] == 'SIM' ? {color: '#3b9756'} : {color: 'rgb(200, 57, 57)'}}/>
          </div>
        </div>

        <div className='score-line'>
          <div className='score-name'>
            <span>Captação e seleção:</span>
          </div>
          <div className='score-circles'>
            <FaCircle className='question-icon' style={respostas[39] == 'SIM' ? {color: '#3b9756'} : {color: 'rgb(200, 57, 57)'}}/>
            <FaCircle className='question-icon' style={respostas[38] == 'SIM' ? {color: '#3b9756'} : {color: 'rgb(200, 57, 57)'}}/>
            <FaCircle className='question-icon' style={respostas[37] == 'SIM' ? {color: '#3b9756'} : {color: 'rgb(200, 57, 57)'}}/>
            <FaCircle className='question-icon' style={respostas[36] == 'SIM' ? {color: '#3b9756'} : {color: 'rgb(200, 57, 57)'}}/>
            <FaCircle className='question-icon' style={respostas[35] == 'SIM' ? {color: '#3b9756'} : {color: 'rgb(200, 57, 57)'}}/>
          </div>
        </div>

        <div className='score-line'>
          <div className='score-name'>
            <span>Gestão e indicadores sobre pessoas (People analytics):</span>
          </div>
          <div className='score-circles'>
            <FaCircle className='question-icon' style={respostas[44] == 'SIM' ? {color: '#3b9756'} : {color: 'rgb(200, 57, 57)'}}/>
            <FaCircle className='question-icon' style={respostas[43] == 'SIM' ? {color: '#3b9756'} : {color: 'rgb(200, 57, 57)'}}/>
            <FaCircle className='question-icon' style={respostas[42] == 'SIM' ? {color: '#3b9756'} : {color: 'rgb(200, 57, 57)'}}/>
            <FaCircle className='question-icon' style={respostas[41] == 'SIM' ? {color: '#3b9756'} : {color: 'rgb(200, 57, 57)'}}/>
            <FaCircle className='question-icon' style={respostas[40] == 'SIM' ? {color: '#3b9756'} : {color: 'rgb(200, 57, 57)'}}/>
          </div>
        </div>

      </div>
      <button onClick={capturarTelaVermelha}>Baixar seu Resultado</button>
      <button onClick={baixarPDF}>Baixar Soluções</button>
      <button onClick={reiniciarQuiz}>Reiniciar Quiz</button>
    </div>
  );
};

export default PaginaVermelha;