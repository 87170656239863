import React, { useState, useEffect } from 'react';
import Inicial from './components/Inicial';

import './App.css';

import Logo  from './assets/logo-neos.png';

function App() {

  return (
    <div className="App">
      <img src={Logo}/>
      <Inicial />
    </div>
  );
}

export default App;